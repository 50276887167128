<div class="content-wrapper" style="background-color: #fff !important;">
    <section class="Leads_manage leads_redesign container2">
        <h3>Open To Decline Leads</h3>
        <p style="font-size: 19px;font-weight: 600;margin-bottom: 0rem;margin-left: 20px">User Id: {{ userId }}</p>
        <p style="font-size: 19px;font-weight: 600;margin-bottom: 0rem;margin-left: 20px">Name: {{ leadsReports.userName }}</p>
        <div class="row">
        <div class="col-md-4">
        <label style="margin-top: 20px;margin-left: 23px; font-weight: 600;">Last Call Status</label><br>
         <form #filterForm="ngForm" (ngSubmit)="filterOpenToDecline(config.currentPage, config.itemsPerPage)" style="margin-left: 23px;">
            <select [(ngModel)]="status" class="form-control" name="filterBy" >
                <option [value]="" disabled>Select Filter</option>
                <option label="Followup" value="Followup">followup</option>
                <option label="Callback" value="Callback">callback</option>
                <option label="Visit Planned" value="Visit Planned">visitPlanned</option>
                <option label="Meeting Planned" value="Meeting Planned">meetingPlanned</option>
                <option label="Visit Done" value="Visit Done">visitDone</option>
                <option label="Meeting Done" value="Meeting Done">meetingDone</option>
            </select>
            <div class="form-group">
                <label for="startDate">Start Date:</label>
                <input type="date" class="form-control" id="startDate" name="startDate" [(ngModel)]="startDate">
            </div>
            <div class="form-group">
                <label for="endDate">End Date:</label>
                <input type="date" class="form-control" id="endDate" name="endDate" [(ngModel)]="endDate">
            </div>
            <div class="form-group ">
                <label> &nbsp;</label>
                <button type="submit" class="fib_system_btn  mr-3">
                  <i class="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>
            <!-- <button type="submit" style="margin-left: 14px;" mat-flat-button> Filter</button> -->
        </form> 
        
    </div>
          <div class="col-md-4 col-lg-2">
            <div class="form-group">
                <label for="sel1">Page Size.</label>
                <select ngModel name="pSize" #pSize="ngModel" name="pageSize" width="100%" class="form-control" (change)="onChangeSize($event.target.value)">
                <option value="" selected>Select Page Size</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
                </select>
            </div>
        </div>
        <div class="col-md-4 col-lg-2">
            <div class="form-group">
                <label for="sel1">Total Item founds :</label>
                <input class="form-control" type="text" value=" {{config.totalItems}}" disabled />
            </div>
        </div>
    </div>
          <div class="col-xs-12 col-sm-12 col-lg-4">
            <!-- Modal -->
            <div class="modal fade" id="multipleLeadsAssignModel" role="dialog" data-keyboard="false" data-backdrop="static">
                <div class="modal-dialog">
                    <!-- Modal content-->
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Select Employee</h4>
                            <button type="button" class="close" data-dismiss="modal">
                <i class="fa fa-window-close" aria-hidden="true"></i>
              </button>
                        </div>
                        <div class="modal-body">
                            <form (submit)="assignMultipleLead($event)">
                                <div class="box-body">
                                    <div class="form-group">
                                        <label>Select Employee</label>
                                        <select class="form-control" (change)="onChangeUserDropBoxOfMultipleAssign($event.target.value)">
                                        <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                                        {{ user.firstName + " " + user.lastName }}
                                        </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="data" checked id="data1" value="fresh">
                                            <label class="form-check-label  radio-label" for="data1"> Fresh Data </label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <!-- <input class="form-check-input" type="radio" name="data" id="data1" value="calling"> -->
                                            <!-- <label class="form-check-label radio-label" for="data1">Calling Data </label> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- /.box-body -->
                                <div class="box-footer">
                                    <button type="submit" class="btn btn-primary"> Submit </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
          
          <!-- Display your data here using *ngFor as you did before -->
          
      <div class="col-xs-12">
        <div class="table-responsive table-fixed leads_table_redesign table-bg">
            <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
                <thead class="borderStyle">
                    <tr>
                        <th>
                            <input class="form-check-input" type="checkbox" id="parent" (change)="selectAll($event.target.checked)" />
                        </th>
                        <th>SN</th>
                        <th>Leads Id</th>
                        <th>Client Name</th>
                        <th *ngIf="isValid">Client Mobile</th>
                        <th style="background-color: #ffd75f21">Email</th>
                        <th>Source</th>
                        <th>Project Name</th>
                        <th>Query Info</th>
                        <th style="background-color: #b17fad26">Status</th>
                        <th>Call Status</th>
                        <th>Last Status</th>
                        <th>Recieved On</th>
                        <th>Assigned Date</th>
                        <th>Last Update</th>
                        <th>Call status Date</th>
                        <th>Visit Status</th>
                        <th>Visit Done Date</th>
                        <th>Virtual Meeting Status</th>
                        <th>Remark</th>
                        <th>Created By</th>
                        <th>Assigned By</th>
                        <!-- <th>Assigned To</th> -->
                    </tr>
                </thead>
                <tbody class="serialNumber">
                    <tr class="content" *ngFor="let report of leadsReport  | paginate: config">
                        <td>
                            <input type="checkbox" id="child_checkbox" (change)="onCheck(report.leads.id, $event)" />
                        </td>
                        <td></td>
                        <td data-toggle="modal" (click)="leadFeedback(report.leads.id)" data-target="#leadsfeedback">
                            {{ report.leads.id }}
                        </td>

                        <td>{{ report.leads.name }}</td>
                        <td *ngIf="isValid">{{ report.leads.phone }}</td>
                        <td style="background-color: hsla(45, 100%, 69%, 0.129); width: 10%">
                            {{ report.leads.email }}
                        </td>
                        <td>{{ report.leads.source }}</td>
                        <td>{{ report.leads.projectName }}</td>
                        <td>{{ report.leads.queryInfo }}</td>
                        <td style="background-color: #b17fad26">
                            {{ report.status }}
                        </td>
                        <td>{{ report.callStatus }}</td>
                        <td>{{ report.lastcallstatus }}</td>
                        <td *ngIf="report.leads.receivedOn != 0">
                            {{ report.leads.receivedOn | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.leads.receivedOn == 0"></td>
                        <td *ngIf="report.assignLeadsDate != 0">
                            {{ report.assignLeadsDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.assignLeadsDate == 0"></td>
                        <td *ngIf="report.updateDate != 0">
                            {{ report.updateDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.updateDate == 0"></td>
                        <td *ngIf="report.callStatusDate != 0">
                            {{ report.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                        </td>
                        <td *ngIf="report.callStatusDate == 0"></td>
                        <td *ngIf="report.visitStatus" style="color: green">Done</td>
                        <td *ngIf="!report.visitStatus" style="color: red">Not Done</td>
                        <td>{{report.visitDoneDate ? (report.visitDoneDate | date: "dd-MM-yyyy hh:mm a") : "" }}</td>
                        <td *ngIf="report.virtualMeetingStatus" style="color: green">
                            Done
                        </td>
                        <td *ngIf="!report.virtualMeetingStatus" style="color: red">
                            Not Done
                        </td>
                        <td>{{ report.message }}</td>
                        <td *ngIf="report.leads.createdBy == null"></td>
                        <td *ngIf="report.leads.createdBy != null">
                            {{ report.leads.createdBy.firstName + " " + report.leads.createdBy.lastName }}
                        </td>
                        <td *ngIf="report.assignedBy == null"></td>
                        <td *ngIf="report.assignedBy != null">
                            {{ report.assignedBy.firstName + " " + report.assignedBy.lastName }}
                        </td>
                        <!-- <td id="tt">
                            <select name="users" (change)="onChange($event.target.value, report.leads.id)">
              <option *ngIf="report.user != null">
                {{ report.user.firstName + " " + report.user.lastName }}
              </option>
              >
              <option *ngIf="report.user == null" value="0">
                Select User
              </option>
              <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                {{ user.firstName + " " + user.lastName }}
              </option>
            </select>
                        </td> -->
                        
                    </tr>
                </tbody>
            </table>
            <pagination-controls (pageChange)="pageChanged($event)">
              </pagination-controls></div>
    </div>
    </section>
    <!-- partial -->
  </div>
  <!-- Modal -->
  <div class="leadfeedback">
    <!-- Modal -->
    <div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-xl">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Leads Feedback</h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xs-12 col-lg-12">
                            <div class="tabs">
                                <div class="tab-2">
                                    <!-- <label for="tab2-1">Feedback</label> -->
                                    <!-- <input id="tab2-1" name="tabs-two" type="radio" checked="checked" /> -->
                                    <div>
                                        <table summary="" class="table table-bordered table-hover table-striped text-center">
                                            <thead>
                                                <tr>
                                                    <th>SN</th>
                                                    <th>Lead id</th>
                                                    <th style="background-color: #b17fad26">Status</th>
                                                    <th>Call Status</th>
                                                    <th>Message</th>
                                                    <th>Time of Feedback</th>
                                                    <th>Call Status Date</th>
                                                    <th>Visit Done Date</th>
                                                    <th>Virtual Meeting Done Date</th>
                                                    <th>Assigned to</th>
                                                </tr>
                                            </thead>
                                            <tbody class="serialNumber">
                                                <tr class="content" *ngFor="let feedback of feedbackList" data-toggle="modal" data-target="#leadsReportmodal">
                                                    <td></td>
                                                    <td>{{ feedback.leads.id }}</td>
                                                    <td style="background-color: #b17fad26">
                                                        {{ feedback.status }}
                                                    </td>
                                                    <td>{{ feedback.callStatus }}</td>
                                                    <td>{{ feedback.message }}</td>
                                                    <td *ngIf="feedback.timeOfFeedback">{{ feedback.timeOfFeedback | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.timeOfFeedback"></td>
                                                    <td *ngIf="feedback.callStatusDate">{{ feedback.callStatusDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.callStatusDate"></td>
                                                    <td *ngIf="feedback.visitDoneDate">{{ feedback.visitDoneDate | date: "dd-MM-yyyy hh:mm a" }}
                                                    </td>
                                                    <td *ngIf="!feedback.visitDoneDate"></td>
                                                    <td *ngIf="feedback.virtualMeetingDoneDate">{{ feedback.virtualMeetingDoneDate | date: "dd-MM-yyyy hh:mm a" }}</td>
                                                    <td *ngIf="!feedback.virtualMeetingDoneDate"></td>
                                                    <td id="tt">
                                                        {{ feedback.user.firstName + " " + feedback.user.lastName }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <!-- <div class="form-group">
                                            <label for="sel1">&nbsp;</label>
                                            <button class="fib_system_btn  mr-3" (click)="exportAsXLSX1()">
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                                        </div> -->
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>