import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth.service';
declare function closeLeadAssignModel(): any;
declare function closeCallLeadFeedbackModel(): any;
import { Leads } from 'src/app/model/leads.model';
import { Users } from 'src/app/model/users.model';

@Component({
  selector: 'app-calling-data',
  templateUrl: './calling-data.component.html',
  styleUrls: ['./calling-data.component.css']
})
export class CallingDataComponent implements OnInit {
  MOBILE: boolean;
  mobile: string = localStorage.getItem('mobile');
  str: any = null;
  callLeads: any[];
  leadsArray: Leads[];
  users: Array<any> = [];
  userList: any = [];
  activeUsers = [];
  selectedIds: Array<any> = [];
  selectedId: Array<any> = [];
  userId: any;
  ROLE: boolean;
  userRole: string;
  dropdownSettings: IDropdownSettings = {};
  inputDateFromString: any;
  inputDateToString: any;
  inputProjectNameString: any;
  onchangeAgentString: any;

  acceptCallStatus: string[] = new Array('Followup', 'Meeting Planned', 'Visit Planned', 'Not Picked', 'Callback', 'Visited');
  leadsStatus: string = '';
  callStatus: string = '';
  statusCheck: boolean = false;
  callStatusArray: string[];
  callStatusBoolean: boolean = false;
  feedbackUser: any = {};
  lead: any = {};
  status: any[] = [
    { id: 1, name: 'All leads' },
    { id: 2, name: 'Not Called' },
    { id: 3, name: 'Accepted' },
    { id: 4, name: 'Rejected' }
  ];
  onchangeStatusString: any;
  usersList: any[];
  isValid: boolean = false;
leadId:any;
  constructor(private auth: AuthService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.auth.startLoader();
    this.userRole = localStorage.getItem('role');
    this.ROLE = this.userRole === 'ADMIN';
    this.isValid = localStorage.getItem('isValid') === 'YES';
    this.MOBILE = this.mobile === '8076042671';

    this.auth.getUsers().subscribe(data => {
      this.users = data;
    });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      itemsShowLimit: 5
    };

    this.auth.getSalesAndActiveUsers().subscribe(data => {
      this.activeUsers = data;
    });

    this.getCallLeads();
    this.auth.getSalesAndActiveUsers().subscribe(data => {
      this.userList = data;
    });

    this.getAgents();
  }

  getAgents() {
    this.auth.getUsers().subscribe(data => {
      const list: Array<any> = [];
      for (const user of data) {
        const obj: any = {};
        obj['id'] = user.id;
        obj['name'] = `${user.firstName} ${user.lastName}`;
        list.push(obj);
      }
      this.usersList = list;
    });
  }

  getCallLeads() {
    this.auth.getCallLeads().subscribe(
      data => {
        this.auth.stopLoader();
        this.callLeads = data;
      },
      err => {
        this.auth.stopLoader();
        alert(err);
      }
    );
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  onCheck(leadId, event) {
    const arrObj = { leadsId: leadId };
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      const index = this.selectedIds.findIndex(x => x['leadsId'] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  assignMultipleLead() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.assignCallLeads(this.userId, this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          closeLeadAssignModel();
          this.getCallLeads();
          alert(data['message']);
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      closeLeadAssignModel();
      alert('Please select leads.');
    }
  }

  declineCallLeads() {
    this.auth.startLoader();
    if (this.selectedIds.length > 0) {
      this.auth.declineCallLeads(this.selectedIds).subscribe(
        data => {
          this.selectedIds = [];
          this.auth.stopLoader();
          this.getCallLeads();
          alert(data['message']);
        },
        error => {
          this.auth.stopLoader();
          this.getCallLeads();
          alert(error);
        }
      );
    } else {
      this.auth.stopLoader();
      alert('Please select leads.');
    }
  }

  assignLead(userId, leadId) {
    const leadsArray = [{ leadsId: leadId }];

    this.auth.assignLeads(userId, leadsArray).subscribe(
      data => {
        alert(data['message']);
      },
      error => {
        alert(error);
      }
    );
  }

  onsubmitFilter(event) {
    const formFilter = event.value;
    let agentID: any;

    if (formFilter.lAgent.length > 0) {
      for (const str of formFilter.lAgent) {
        agentID = str.id;
        this.auth.filterLeadsBYUserId(agentID).subscribe(
          data => {
            this.callLeads = data;
            this.auth.stopLoader();
          },
          error => {
            this.auth.stopLoader();
          }
        );
      }
    } else {
      agentID = 0;
    }
  }

  feedbackStatus(str) {
    this.leadsStatus = str;
    if (str === 'Open') {
      this.saveAndAssigneCallLeadsToLeads(this.lead, this.feedbackUser['id']);
      this.callStatusArray = this.acceptCallStatus;
      this.callStatusBoolean = false;
    } else if (str === 'Decline') {
      this.callStatusBoolean = true;
      this.statusCheck = false;
    } else {
      this.callStatusArray = [];
      this.leadsStatus = null;
      this.callStatusBoolean = false;
      this.statusCheck = false;
      alert('Please select status');
    }
  }

  leadStatusUpdate(obj, userObj) {
    this.lead = obj;
    this.feedbackUser = userObj;
    this.leadsStatus = '';
    this.callStatus = '';
    this.statusCheck = false;
    this.callStatusBoolean = false;
    this.leadId = obj.id;
  }

  saveAndAssigneCallLeadsToLeads(leads, userId) {
    this.auth.startLoader();
    this.auth.saveAndAssignLeads(leads, userId).subscribe(
      data => {
        this.auth.stopLoader();
        this.lead = data;
        this.statusCheck = true;
      }
    );
  }

  feedbackCallStatus(str) {
    this.callStatus = str;
    if (this.callStatus !== '') {
      this.callStatusBoolean = true;
    } else {
      this.callStatusBoolean = false;
      this.callStatus = null;
      alert('Please select call status');
    }
  }

  saveRemark(event) {
    var date: any;
    var remark: string = "";
    let target = event.target;
let lead=this.leadsArray
    var feedbackObj: any = {};

    if (target.querySelector("#remarkArea").value != null) {
      remark = target.querySelector("#remarkArea").value;
    }

      if (target.querySelector("#chooseDate") != null) {
        date = target.querySelector("#chooseDate").value;
        if (date.trim().length > 0) {
          date = this.auth.toDateAndTimeTimestamp(date);
        }
      }


    if(this.callStatus == 'OPEN'){
      if (remark.length <= 0 && date <= 0) {
        alert("Remark and date should not be empty.");
        return;
      }
    }else{
      if (remark.length <= 0) {
        alert("Remark should not be empty.");
        return;
      }
    }

      feedbackObj["message"] = remark;
      feedbackObj["status"] = this.leadsStatus;
      feedbackObj["callStatus"] = this.callStatus;
      feedbackObj["user"] = this.feedbackUser;

      if (this.leadsStatus == "open") {
        if (
          this.callStatus === "Visit Done" ||
          this.callStatus === "Virtual Meeting Done" ||
          this.callStatus === "Meeting Done"
        ) {
          if (this.callStatus === "Visit Done") {
            feedbackObj["visitStatus"] = true;
            feedbackObj["visitDoneDate"] = date;
          } else if (this.callStatus === "Virtual Meeting Done") {
            feedbackObj["virtualMeetingStatus"] = true;
            feedbackObj["virtualMeetingDoneDate"] = date;
          } else if (this.callStatus === "Meeting Done") {
            feedbackObj["meetingStatus"] = true;
            feedbackObj["meetingDoneDate"] = date;
          }
        }
        feedbackObj["callStatusDate"] = this.auth.toTimestamp(date);
      }
      this.auth.saveLeadsFeedback(this.leadId, feedbackObj).subscribe(
        (data) => {
          this.auth.filterLeads(this.lead).subscribe((data) => {
            this.leadsArray = data;
          });
          alert(data["output"]);
        },
        (err) => {
          console.log(err);
          alert("Something wrong try again.");
        }
      );
  }
  

  updateCallLeadsAndReport(remark) {
    const callLeadsObj: any = {};
    callLeadsObj['feedback'] = remark;
    callLeadsObj['status'] = this.leadsStatus;
    callLeadsObj['updateDate'] = new Date().getTime();
    callLeadsObj['user'] = this.feedbackUser;
    callLeadsObj['rejected'] = false;
    callLeadsObj['phone'] = this.lead['phone'];
    this.auth.updateCallLeadsAndReport(callLeadsObj).subscribe(
      data => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert(data['output']);
      },
      err => {
        this.auth.stopLoader();
        closeCallLeadFeedbackModel();
        alert('Something went wrong.');
      }
    );
  }
}
