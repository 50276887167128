import { Component, OnInit, Input } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";

@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.css"],
})
export class AttendanceComponent implements OnInit {
  users: Users[];
  userAttendanceList: any[];

  inputDateFromString: any;
  inputDateToString: any;

  dropdownSettings: IDropdownSettings = {};

  userId: any;

  filter: any;

  myDate = new Date();
  currDate: string;
  userList: any[];
  constructor(private datePipe: DatePipe, private auth: AuthService) {
    this.currDate = this.datePipe.transform(this.myDate, "dd-MM-yyyy");
  }

  ngOnInit() {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.filter = false;
    this.userId = 0;
    this.userAttendance();
    this.auth.getAllActiveUser().subscribe((data) => {
      this.users = data;
    });

    this.getAgents();
  }

  userAttendance() {
    this.auth.startLoader();
    this.auth.getTodayAttendanceList().subscribe(
      (data) => {
        this.userAttendanceList = data;
        this.auth.stopLoader();
      },
      (error) => {
        console.error(error);
        this.auth.stopLoader();
      }
    );
  }

  liveLocation(id) {
    window.open("/admin/userGoogleMap/" + id + "/" + "attendance");
  }

  userLiveLocation() {
    if (this.userId <= 0) {
      alert("Please select agent.");
    } else {
      window.open("/admin/userGoogleMap/" + this.userId);
    }
  }

  agentIdChange(id) {
    this.userId = id;
  }

  isClickedOnAttendance = false;

  userAttendanceFilter(event) {
    this.auth.startLoader();
    let form = event.value;
    if (form.emp.length > 0) {
      this.userId = form.emp[0].id;
      if (this.userId > 0) {
        this.isClickedOnAttendance = true;
      } else {
        this.isClickedOnAttendance = false;
      }
    } else {
      this.isClickedOnAttendance = false;
      this.userId = 0;
    }

    this.inputDateFromString = this.auth.toTimestamp(form.dFrom);
    this.inputDateToString = this.auth.toTimestamp(form.dTo);

    if (this.userId <= 0) {
      this.userAttendance();
      return;
    }

    this.auth
      .getUserAttendanceList(
        this.userId,
        this.inputDateFromString,
        this.inputDateToString
      )
      .subscribe(
        (data) => {
          this.userAttendanceList = data;
          this.auth.stopLoader();
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
  }

  exportAttendance() {
    if (this.userId <= 0) {
      alert("Please select the user");
      return;
    } else {
      this.auth
        .getUserAttendanceList(
          this.userId,
          this.inputDateFromString,
          this.inputDateToString
        )
        .subscribe(
          (data) => {
            this.auth.stopLoader();
            this.exportAttendanceInExcel(data);
          },
          (error) => {
            this.auth.stopLoader();
          }
        );
    }
  }

  private exportAttendanceInExcel(list) {
    var exportList = [];
    var userName;
    for (let obj of list) {
      userName = obj.user.firstName + " " + obj.user.lastName;
      var json_obj = {
        Date: obj.date,
        "In Time": this.auth.toTime(obj.inTime),
        "Out Time": this.auth.toTime(obj.outTime),
        "Working Hour": obj.workingHour,
      };
      exportList.push(json_obj);
    }
    this.auth.exportAsExcelFile(exportList, userName + " Attendance");
  }

  longToDateFormate(long, format) {
    var date = new Date(long);
    return this.datePipe.transform(
      date,
      "hh:mm a                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           "
    );
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }
}
