import { Component, OnInit } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { NgForm } from "@angular/forms";

@Component({
  selector: "app-attendance",
  templateUrl: "./attendance.component.html",
  styleUrls: ["./attendance.component.css"],
})
export class AttendanceComponent implements OnInit {
  users: Users[];
  userAttendanceList: any[];
  userList: any[] = [];
  lat: any;
  long: any;
  inputDateFromString: number = 0;
  inputDateToString: number = 0;
  currDate: string;
  dropdownSettings: IDropdownSettings = {};
  selectedUserAttendance: any[];
  selectedUser: any[] = [];
  selectedUserId: any;

  constructor(private datePipe: DatePipe, private auth: AuthService) {}

  ngOnInit() {
    this.getAgents();
    this.dropdownSettings = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      allowSearchFilter: true,
    };
    this.userAttendance(localStorage.getItem("id"));
  }

  onUserSelect(selectedUser: any) {
    this.auth.startLoader();
    this.userAttendance(selectedUser[0].id);
    this.auth.stopLoader();
  }

  userAttendance(selectedUserId?: string) {
    const myDate = new Date().getTime();
    this.auth
      .getUserAttendanceList(selectedUserId, 0, myDate)
      .subscribe(
        (data) => {
          this.userAttendanceList = data.map((item) => ({
            ...item,
            firstName: item.user.firstName,
          }));
          this.auth.stopLoader();
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
  }

  userAttendanceFilter(filterForm: NgForm) {
    this.auth.startLoader();
    let form = filterForm.value;

    this.inputDateFromString = this.auth.toTimestamp(form.dFrom);
    this.inputDateToString = this.auth.toTimestamp(form.dTo);

    let userId = localStorage.getItem("id"); // Default to logged-in user's ID

    // Check if a user is selected from the dropdown
    if (this.selectedUser.length > 0) {
      userId = this.selectedUser[0].id; // Use the selected user's ID
    }

    if (this.inputDateFromString > 0 && this.inputDateToString > 0) {
      this.auth
        .getUserAttendanceList(userId, this.inputDateFromString, this.inputDateToString)
        .subscribe(
          (data) => {
            this.userAttendanceList = data;
            this.auth.stopLoader();
          },
          (error) => {
            this.auth.stopLoader();
          }
        );
    } else {
      this.userAttendance(userId); // Fetch attendance for the selected user if any
    }
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      this.userList = data.map((user) => ({
        id: user.id,
        name: user.firstName + " " + user.lastName,
      }));
    });
  }
}
