<div class="content-wrapper" style="background-color: #fff !important;">
  <section class="Leads_manage leads_redesign">

    <!--Start filter form section -->

    <div class="bord">
      <div class="col-xs-12 col-sm-3 col-lg-12 ">
        <div class="form-group">
          <div class="date_filter_tab toggle__btn">
            <p class="" for="usr" value="assigned">Filter :</p>
            <button class="fib_system_btn">
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </button>
          </div>
          <div class="collapse" id="collapsefilters">
            <form #filterForm="ngForm" (submit)="onsubmitFilter(filterForm)">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-xs-12 col-sm-3 col-lg-12 ">
                    <div class="form-group">
                      <div class="date_filter_tab date_filter_btn">
                        <p class="" for="usr" value="assigned">Date Filter :</p>
                        <button class="fib_system_btn" type="button" data-toggle="collapse"
                          data-target="#collapseassigned" aria-expanded="false" aria-controls="collapseExample">
                          <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                      </div>

                      <div class="form-group collapse label-group " id="collapseassigned">
                        <div class="row">
                          <div class="col-xs-12 col-sm-12 col-lg-12 ">
                            <div class="form-group">
                              <label class="label-text" for="usr" value="receivedon">Recieved Date:</label>

                              <div>
                                <label for="usr">From:</label>
                                <input ngModel name="rdFrom" #rdFrom="ngModel" type="date" class="form-control"
                                  id="rFrom" />
                                <label for="usr">To:</label>
                                <input ngModel name="rdTo" #rdTo="ngModel" type="date" class="form-control" id="rTo" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group">
                      <label for="sel1">Source:</label>
                      <ng-multiselect-dropdown ngModel name="lSource" #lSource="ngModel" class="im_multi"
                        [placeholder]="'Source Name'" [data]="sourceNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-4">
                    <div class="form-group">
                      <label for="usr">Project Name:</label>
                      <ng-multiselect-dropdown ngModel name="lProject" #lProject="ngModel" class="im_multi"
                        [placeholder]="'Projects Name'" [data]="projectNameList" [settings]="dropdownSettings">
                      </ng-multiselect-dropdown>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-lg-2">
                    <div class="form-group">
                      <label for="sel1">Total Item founds :</label>
                      <input class="form-control" type="text" value=" {{itemCount}}" disabled />
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-lg-2 d-flex ">
                    <div class="form-group ">
                      <label> &nbsp;</label>
                      <button data-toggle="tooltip" data-placement="top" title="Filter" type="submit"
                        class="fib_system_btn  mr-3">
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-4">
                  <div class="form-group">
                    <form>
                      <div class="row">
                        <!-- <div class="col-xs-12 col-sm-12 col-lg-8">
                            <div class="form-group">
                              <input type="text" class="form-control" id="search" name="search" value=""
                                placeholder="Search client name or phone" />
                            </div>
                          </div> -->
                        <div class="col-xs-12 col-sm-12 col-lg-4 d-flex ">
                          <!-- <div class="form-group">
                              <button type="submit" class="fib_system_btn mr-3">
                                <i class="fa fa-search" aria-hidden="true"></i>
                              </button>
                            </div> -->
                          <div class="form-group">
                            <button type="button" class="fib_system_btn  mr-3" data-toggle="modal"
                              data-target="#multipleLeadsAssignModel">
                              <i class="fa fa-user-plus" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div class="form-group">
                            <button type="button" class="fib_system_btn  mr-3" (click)="spamLeads()">
                              <i class="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                          <div class="form-group">
                            <a href="admin/importLeads/" class="fib_system_btn  mr-3">
                              <i class="fa fa-upload" aria-hidden="true"></i>
                            </a>
                          </div>
                          <div class="form-group">
                            <app-add-lead></app-add-lead>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-lg-4">
                  <!-- Modal -->
                  <div class="modal fade" id="multipleLeadsAssignModel" role="dialog" data-keyboard="false"
                    data-backdrop="static">
                    <div class="modal-dialog">
                      <!-- Modal content-->
                      <div class="modal-content">
                        <div class="modal-header">
                          <h4 class="modal-title">Select Employee</h4>
                          <button type="button" class="close" data-dismiss="modal">
                            <i class="fa fa-window-close" aria-hidden="true"></i>
                          </button>
                        </div>

                        <div class="modal-body">
                          <form role="form" (submit)="assignMultipleLead()">
                            <div class="box-body">
                              <div class="form-group">
                                <label>Select Employee</label>
                                <select class="form-control" (change)="
                                      onChangeUserDropBoxOfMultipleAssign(
                                        $event.target.value
                                      )
                                    ">
                                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                                    {{ user.firstName + " " + user.lastName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- /.box-body -->
                            <div class="box-footer">
                              <button type="submit" class="btn btn-primary">
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!--End filter form section -->



    <div class="col-xs-12">
      <div class="table-responsive table-fixed leads_table_redesign table-bg">
        <table summary="" class="table table-bordered table-hover table-striped text-center borderStyle" style="background-color: #ecf7fc;">
          <thead>
            <tr>
              <th>
                <input class="form-check-input" type="checkbox" id="parent"
                  (change)="selectAll($event.target.checked)" />
              </th>
              <th>SN</th>
              <th>Leads Id</th>
              <th style="background-color: #ffd75f21">Client Name</th>
              <th *ngIf="isValid">Client Mobile</th>
              <th>Email</th>
              <th>Source</th>
              <th style="background-color: #0ce2e21f">Project Name</th>
              <th>Query Info.</th>
              <th>Leads Received Date</th>
              <th>Leads Assigned Date</th>
              <th>Assigned Project</th>
            </tr>
          </thead>
          <tbody class="serialNumber">
            <tr class="content" *ngFor="let leads of leadsArray">    
              <!-- *ngIf="leads.source !='Housing2'"   my implementation where testing is to be done but not simplified in code--> 
              <td>
                <div>
                  <input type="checkbox" id="child_checkbox" (change)="onCheck(leads.id, $event)" />
                </div>
              </td>
              <td>
              </td>
              <!-- <td>{{ leads.id }}</td> -->
              <td data-toggle="modal" data-target="#leadsfeedback" (click)="onIdClick(leads.id, leads.name, leads.phone,leads.source,leads.projectName)"> {{ leads.id }} </td>
              <td style="background-color: #ffd75f21">{{ leads.name }}</td>
              <td *ngIf="isValid">{{ leads.phone }}</td>
              <td>{{ leads.email }}</td>
              <td>{{ leads.source }}</td>
              <td style="background-color: #0ce2e21f">
                {{ leads.projectName }}
              </td>
              <td style="width: 10%">{{ leads.queryInfo }}</td>
              <td *ngIf="leads.receivedOn != 0">{{ leads.receivedOn | date:"dd-MM-yyyy hh:mm a" }}</td>
              <td *ngIf="leads.receivedOn == 0"></td>
              <td *ngIf="leads.assignedDate != 0">{{ leads.assignedDate | date:"dd-MM-yyyy hh:mm a" }}</td>
              <td *ngIf="leads.assignedDate == 0"></td>
              <td id="tt">
                <select name="users" (change)="assignLead($event.target.value, leads.id)">
                  <option *ngIf="leads.user != null">
                    {{ leads.user.firstName + " " + leads.user.lastName }}
                  </option>
                  >
                  <option *ngIf="leads.user == null" value="0">
                    Select User
                  </option>
                  <option *ngFor="let user of activeUsers" value="{{ user.id }}">
                    {{ user.firstName + " " + user.lastName }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--end of .table-responsive-->
    </div>


  </section>
  <!-- partial -->
</div>
<!-- Modal -->
<div class="modal fade" id="leadsfeedback" role="dialog" data-keyboard="false" data-backdrop="static">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">×</button>
        <h4 class="modal-title">Update Leads Form</h4>
      </div>
      <div class="modal-body">
        <form name="addLeadForm" (submit)="updateUser($event)">
          <div class="box-body">
            <div class="row">
              <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                  <label for="name">Client Name</label>
                  <input
                    type="text"
                    class="form-control"
                    [value]="lead.name"
                    id="name"
                    name="name"
                  />
                </div>
              </div> 
              <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                  <label for="phone">Phone</label>
                  <input
                    type="text"
                    class="form-control"
                    [value]="lead.phone"
                    id="phone"
                    name="phone"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                  <label for="text">Source</label>
                  <input
                    type="text"
                    class="form-control"
                    [value]="lead.source"
                    id="source"
                    name="source"
                  />
                </div>
              </div>
              <div class="col-xs-12 col-lg-6">
                <div class="form-group">
                  <label for="text">Project Name</label>
                  <input
                    type="text"
                    class="form-control"
                    [value]="lead.projectName"
                    id="projectName"
                    name="projectName"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="box-footer">
            <button type="submit" class="btn btn-primary btn-block">
              Update User
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
