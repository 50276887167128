import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-show-review',
  templateUrl: './show-review.component.html',
  styleUrls: ['./show-review.component.css']
})
export class ShowReviewComponent implements OnInit {
  data: any;
  user_id:any;
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.getAllActiveUser().subscribe((res) => {
      this.data = res;

    });
  }
  onSubmit(){
    if (this.user_id) {
      this.router.navigate(['admin/review-pending'], {
        queryParams: { id: this.user_id }
      });
    }
  }
  onUpdate(){
    if (this.user_id) {
      this.router.navigate(['admin/performance'], {
        queryParams: { id: this.user_id }
      });
    }
  }
}
