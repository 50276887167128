<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar" style="background-color: #0c0b0c;position: fixed;">
    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar">
        <!-- Sidebar user panel -->
        <div class="user-panel" style="background: #262626;">
            <div class="pull-left image">
                <img src="./../../assets/dist/img/user.png" class="img-circle" alt="User Image">
            </div>
            <div class="pull-left info" style="color: white;">
                <p>{{name}}</p>
                <p>{{mobile}}</p>
            </div>
        </div>
        <!-- /.search form -->
        <!-- sidebar menu: : style can be found in sidebar.less -->
        <ul class="sidebar-menu" data-widget="tree">
            <!-- <li class="header">MAIN NAVIGATION</li> -->
            <li>
                <a href="/crm">
                    <i class="fa fa-dashboard"></i> <span>DASHBOARD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">new</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/openLead">
                    <i class="fa fa-folder-open-o" aria-hidden="true"></i> <span>OPEN LEAD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-green">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/lead">
                    <i class="fa fa-calculator" aria-hidden="true"></i> <span>NEW LEADS</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-aqua">new</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/leadReport">
                    <i class="fa fa-bar-chart" aria-hidden="true"></i> <span>LEAD REPORT</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="crm/attendance">
                    <i class="fa fa-bell" aria-hidden="true"></i> <span>ATTENDANCE</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-black">New</small> -->
                    </span>
                </a>
            </li>

            <!-- <li>
                <a href="crm/meetingDetails">
                    <i class="fa fa-folder-open-o" aria-hidden="true"></i> <span>MEETING</span>
                    <span class="pull-right-container">
                    </span>
                </a>
            </li> -->

            <li>
                <a href="crm/callingData">
                    <i class="fa fa-phone" aria-hidden="true"></i> <span>CALLING DATA</span>
                    <span class="pull-right-container">
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/profile">
                    <i class="fa fa-user" aria-hidden="true"></i> <span>PROFILE</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/show-crm-child-review">
                    <i class="fa fa-user" aria-hidden="true"></i> <span>SHOW REVIEW</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>
            <li>
                <a href="/crm/updatePassword">
                    <i class="fa fa-lock"></i> <span>CHANGE PASSWORD</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>

            <li>
                <a href="/crm/markAttendance">
                    <i class="fa fa-bell" aria-hidden="true"></i> <span>MARK  ATTENDANCE</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-yellow">New</small> -->
                    </span>
                </a>
            </li>

            <li>
                <a (click)="Logout()" href="/">
                    <i class="fa fa-sign-out" aria-hidden="true"></i> <span>LOGOUT</span>
                    <span class="pull-right-container">
                        <!-- <small class="label pull-right bg-black">New</small> -->
                    </span>
                </a>
            </li>


        </ul>
    </section>
    <!-- /.sidebar -->
</aside>