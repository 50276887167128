
import { Component, OnInit, NgModule, Pipe } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";

import { DomSanitizer } from "@angular/platform-browser";
import { IDropdownSettings } from "ng-multiselect-dropdown";

import { AuthService } from "src/app/services/auth.service";

import { ActivatedRoute } from '@angular/router';

declare function closeLeadsAssignModel(): any;
declare function getRadioValue(): any;



@Component({
  selector: 'app-review-pending',
  templateUrl: './review-pending.component.html',
  styleUrls: ['./review-pending.component.css']
})
@NgModule({
  declarations: [],
  imports: [HttpClientModule, RouterTestingModule, HttpClient],
  providers: [DatePipe],
})
@Pipe({ name: "safeResourceUrl" })
export class ReviewPendingComponent implements OnInit {
  userId:any;
  leadsReport: any[];
  leadsReport1: any[];
  feedbackList: any[];
  selectedId: Array<any> = [];
  selectedIds: Array<any> = [];
  numberOfLeads: number;
  activeUsers: any[];
  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  dropdownSettings1: IDropdownSettings = {};
  inp: any;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];
  itemCount: any;
  expanded = false;
  lastcallstatus: Array<any> = [];
  selectedlastcallstatus : Array<any> = [];

  constructor(
    private auth: AuthService,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) {}

  public transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.inp = document.getElementById("parent");
    this.isValid = localStorage.getItem("isValid") == "YES";

    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.auth.pendingReview(this.userId).subscribe((res) => {
      this.leadsReport = res;
    const numberOfLeads = res.length;
    this.numberOfLeads = numberOfLeads;
    console.log("efrgth"+res)
    });
    // this.userId = this.route.snapshot.queryParamMap.get('id');
    // this.auth.opendeclinedata(this.userId).subscribe((data) => {
    //   this.leadsReport = data;
   
    // });
   
  }
 

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeads1(leadsId).subscribe(
      (data) => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex((x) => x.id === user.id);
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }

  expendReport(leadId) {
    this.expanded = !this.expanded;
    if (this.expanded) {
      this.auth.startLoader();
      this.auth.getLeadReportById(leadId).subscribe(
        (data) => {
          this.leadsReport1 = data;
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }



}



