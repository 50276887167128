import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CRMRoutingModule } from './crm-routing.module';
import { AdminComponent } from './admin/admin.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { CallingDataComponent } from './calling-data/calling-data.component';
import { CommonComponent } from './common/common.component';
import { LeadReportComponent } from './lead-report/lead-report.component';
import { LeadsComponent } from './leads/leads.component';
import { OpenleadComponent } from './openlead/openlead.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxUiLoaderConfig, NgxUiLoaderModule, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPaginateModule } from 'ngx-paginate';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from 'angularfire2/database';
import { AgmCoreModule } from '@agm/core';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { AddLeadComponent } from './add-lead/add-lead.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ChartComponent } from './chart/chart.component';
import { MarkAttendanceComponent } from './mark-attendance/mark-attendance.component';
import { PerformanceComponent } from './performance/performance.component';
import { ShowAllLeadsResponceComponent } from './show-all-leads-responce/show-all-leads-responce.component';
import { ShowCrmChildReviewComponent } from './show-crm-child-review/show-crm-child-review.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: 'red',
  fgsPosition: POSITION.centerCenter,
  fgsSize: 40,
  fgsType: SPINNER.foldingCube, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};

@NgModule({
  declarations: [
    AddLeadComponent,
    AdminComponent,
    AttendanceComponent,
    CallingDataComponent,
    CommonComponent,
    LeadReportComponent,
    LeadsComponent,
    OpenleadComponent,
    HeaderComponent,
    SidebarComponent,
    UpdateUserComponent,
    UpdatePasswordComponent,
    ChartComponent,
    MarkAttendanceComponent,
    PerformanceComponent,
    ShowAllLeadsResponceComponent,
    ShowCrmChildReviewComponent,
  ],
  imports: [
    CommonModule,
    CRMRoutingModule,
    UiSwitchModule,
    NgxPaginationModule,
    NgxPaginateModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgMultiSelectDropDownModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCX_2By8eOjZ-NlHfPfKnid4BmZJaoFDyY',
      // libraries: ["places", "geometry"]
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
    }),
  ]
})
export class CRMModule { }
