import { Component, OnInit, NgModule, Pipe } from "@angular/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { RouterTestingModule } from "@angular/router/testing";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { Users } from "src/app/model/users.model";
import { AuthService } from "src/app/services/auth.service";

declare function closeLeadsAssignModel(): any;

@Component({
  selector: "app-lead-report",
  templateUrl: "./lead-report.component.html",
  styleUrls: ["./lead-report.component.css"],
})
@NgModule({
  imports: [HttpClientModule, RouterTestingModule, HttpClient],
  providers: [DatePipe],
})
@Pipe({ name: "safeResourceUrl" })
export class LeadReportComponent implements OnInit {
  leadsReport: any[];
  exportLeadsReport: any[];
  users: Users[];
  feedbackList: any[];
  selectedId: Array<any> = [];

  onchangeStatusString: any;
  onchangeCallStatusString: any;
  onchangeAgentString: any;
  onchangeSourceString: any;
  onchangeTransfered: any;
  onchangeVisitStatus: any;

  selectedIds: Array<any> = [];

  reFromString: any;
  reToString: any;

  asFromString: any;
  asToString: any;

  lmFromString: any;
  lmToString: any;

  csFromString: any;
  csToString: any;

  inputProjectNameString: any;
  activeUsers: any[];
  sourceString: any[];
  projectNameString: any[];
  private pageSize: number = 20;
  public config = {
    itemsPerPage: 20,
    currentPage: 0,
    totalItems: 0,
  };

  mobile: string = localStorage.getItem("mobile");
  userRole: string;
  recordings: any[] = null;
  orderBy: string = "DESC";
  sortBy: string = "receivedOn";

  fileUrl: any;

  leadsStatus: any[] = [
    { id: 1, name: "Open" },
    { id: 2, name: "Decline" },
    { id: 3, name: "Close" },
  ];

  itemCount: any;

  openCallStatus: any[] = [
    { id: 1, name: "Followup" },
    { id: 2, name: "Callback" },
    { id: 3, name: "Not Connected" },
    { id: 4, name: "Visit Planned" },
    { id: 5, name: "Meeting Planned" },
    { id: 6, name: "Virtual Meeting Planned" },
    { id: 7, name: "Visit Done" },
    { id: 8, name: "Meeting Done" },
    { id: 9, name: "Virtual Meeting Done" },
  ];

  declineCallStatus: any[] = [
    { id: 1, name: "Invalid Number" },
    { id: 1, name: "Not Interested" },
    { id: 1, name: "Already Purchased" },
    { id: 2, name: "Budget Issue" },
    { id: 3, name: "Location Issue" },
    { id: 4, name: "Loan Issue" },
    { id: 5, name: "Followup Done, Not Responding" },
    { id: 6, name: "Plan Postponed" },
  ];

  private page: number = 1;
  private filterStr: any;
  private json_obj = {
    reFromDate: 0,
    reToDate: 0,
    asFromDate: 0,
    asToDate: 0,
    csFromDate: 0,
    csToDate: 0,
    statusList: null,
    callStatusList: null,
    assignedByIds: null,
    assignedToIds: null,
    projectsString: null,
    sourceString: null,
    orderBy: "DESC",
    sortBy: "receivedOn",
  };

  leadsAssignedFrequencyString: any;
  typeOfDateString: any;

  projectNameList: Array<any> = [];
  sourceNameList: Array<any> = [];
  userList: Array<any> = [];
  statusList: Array<any> = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};
  selectedProjects: Array<any> = [];
  selectedSource: Array<any> = [];
  selectedEmp: Array<any> = [];
  selectedAll: any;
  inp: any;
  userId: any;
  isValid: boolean = false;
  callStatus: Array<any> = [];
  openString: any;
  declineString: any;
  closeString: any;
  selectedLeadStatus: Array<any> = [];
  selectedCallStatus: Array<any> = [];

  constructor(
    private auth: AuthService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  public transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngOnInit() {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);

    this.inp = document.getElementById("parent");
    this.isValid = localStorage.getItem("isValid") == "YES";

    this.dropdownSettings = {
      singleSelection: false,
      allowSearchFilter: true,
      idField: "id",
      textField: "name",
      itemsShowLimit: 1,
    };

    this.getProjects();

    this.getAgents();

    this.getSources();

    this.auth.startLoader();

    this.getFilteredLeadsReportByPage(this.json_obj);

    this.auth.getSalesAndActiveUsers().subscribe((data) => {
      this.activeUsers = data;
    });
  }

  getRecordingOfLeads(leadsId, userId) {
    this.recordings = new Array();
    this.auth.callRecordOfLeads(leadsId, userId).subscribe((data) => {
      this.recordings = data;
    });
  }

  onCheck(leadId, event) {
    var arrObj = {};
    arrObj["leadsId"] = leadId;
    if (event.target.checked) {
      this.selectedIds.push(arrObj);
    } else {
      this.inp.checked = false;
      let index = this.selectedIds.findIndex((x) => x["leadsId"] === leadId);
      this.selectedIds.splice(index, 1);
    }
  }

  selectAll(isChecked: boolean) {
    var input = document.getElementsByTagName("input");
    if (isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === false
        ) {
          input[i].checked = true;
        }
      }
      for (var i = 0; i < this.leadsReport.length; i++) {
        var obj = {};
        obj["leadsId"] = this.leadsReport[i].leads.id;
        this.selectedIds.push(obj);
      }
    }
    if (!isChecked) {
      this.selectedIds = [];
      for (var i = 0; i < input.length; i++) {
        var obj = {};
        if (
          input[i].type === "checkbox" &&
          input[i].id === "child_checkbox" &&
          input[i].checked === true
        ) {
          input[i].checked = false;
        }
      }
    }
  }

  onChangeUserDropBoxOfMultipleAssign(user_id) {
    this.userId = user_id;
  }

  assignMultipleLead() {
    this.auth.startLoader();
    this.auth.assignLeads(this.userId, this.selectedIds).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.inp.checked = false;
        this.selectAll(false);
        alert(data["message"]);
        closeLeadsAssignModel();
      },
      (error) => {
        this.auth.stopLoader();
        alert(error);
      }
    );
  }

  onChangeSize(event) {
    this.pageSize = event;
  }

  getLeadsReportByPage() {
    this.filterStr = "normal";
    this.auth.getLeadsReportByPageNumber(this.page).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.leadsReport = data["content"];
        this.config.totalItems = data["totalElements"];
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  getFilteredLeadsReportByPage(jsonObj) {
    this.auth.startLoader();
    this.auth.filterReport(jsonObj, this.page, this.pageSize).subscribe(
      (data) => {
        console.log('Data received before filtering:', data);
        this.leadsReport = data["content"];
        this.config.totalItems = data["totalElements"];
        this.itemCount = data["totalElements"];
        this.config.itemsPerPage = this.pageSize;
        this.auth.stopLoader();
        console.log('Filtered data:', this.leadsReport);
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }
  

  pageChanged(event) {
    this.auth.startLoader();
    this.page = event;
    this.config.currentPage = event;
    console.log('Filter JSON Object:', this.json_obj); // Add this line
    this.getFilteredLeadsReportByPage(this.json_obj);
  }
  

  leadFeedback(leadsId, userId) {
    this.auth.startLoader();
    this.auth.getFeedbackOfLeadsEmployee(leadsId,userId).subscribe(
      (data) => {
        this.feedbackList = data;
        this.auth.stopLoader();
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
    this.getRecordingOfLeads(leadsId, userId);
  }

  activeUser(userList: any[]) {
    for (let user of userList) {
      if (user.deleted) {
        let index = userList.findIndex((x) => x.id === user.id);
        userList.splice(index, 1);
      }
    }
    return userList;
  }

  onChange(userId, leadsId) {
    this.auth.startLoader();
    this.selectedId = [];
    var arrObj = {};
    arrObj["leadsId"] = leadsId;
    this.selectedId.push(arrObj);
    if (userId != 0) {
      this.auth.assignLeads(userId, this.selectedId).subscribe(
        (data) => {
          this.auth.stopLoader();
          alert(data["message"]);
        },
        (error) => {
          this.auth.stopLoader();
        }
      );
    }
  }

  onChangeOrderBy(str) {
    if (str != null || str != "") {
      this.orderBy = str.trim();
    } else {
      this.orderBy = "DESC";
    }
  }

  onChangeSortBy(str) {
    if (str != null || str != "") {
      this.sortBy = str.trim();
    } else {
      this.sortBy = "receivedOn";
    }
  }

  onsubmitFilter(event) {
    this.inp.checked = false;
    this.selectAll(false);
    this.page = 1;
  
    let formFilter = event.value;
  
    let projects: Array<any> = [];
    let assignedTo: Array<any> = [];
    let assignedBy: Array<any> = [];
    let status: Array<any> = [];
    let callStatus: Array<any> = [];
  
    if (formFilter.lAssignedBy.length > 0) {
      for (var str of formFilter.lAssignedBy) {
        assignedBy.push(str.id);
      }
    }
  
    if (formFilter.lAssignedTo.length > 0) {
      for (var str of formFilter.lAssignedTo) {
        assignedTo.push(str.id);
      }
    }
  
    if (formFilter.lStatus.length > 0) {
      for (var str of formFilter.lStatus) {
        status.push(str.name);
      }
    }
  
    if (formFilter.lProject.length > 0) {
      for (var str of formFilter.lProject) {
        projects.push(str.name);
      }
    }
  
    if (formFilter.lcStatus.length > 0) {
      for (var str of formFilter.lcStatus) {
        callStatus.push(str.name);
      }
    }
  
    this.json_obj = {
      reFromDate: this.auth.toTimestamp(formFilter.rdFrom),
      reToDate: this.auth.toTimestamp(formFilter.rdTo),
      asFromDate: this.auth.toTimestamp(formFilter.adFrom),
      asToDate: this.auth.toTimestamp(formFilter.adTo),
      csFromDate: this.auth.toTimestamp(formFilter.csdFrom),
      csToDate: this.auth.toTimestamp(formFilter.csdTo),
      statusList: status,
      callStatusList: callStatus,
      assignedByIds: assignedBy,
      assignedToIds: assignedTo,
      projectsString: projects,
      sourceString: [],
      orderBy: formFilter.orderBy,
      sortBy: formFilter.sortBy,
    };
    console.log('Filter JSON Object:', this.json_obj); 
    this.auth.startLoader();
    this.inp.checked = false;
    this.selectAll(false);
    this.page = 1;
    console.log('Filter JSON Object:', this.json_obj);
  
    this.getFilteredLeadsReportByPage(this.json_obj);
  }
  
  exportAsXLSX() {
    this.auth.startLoader();
    this.auth.filterLeadsReportForExcelExport(this.json_obj).subscribe(
      (data) => {
        this.auth.stopLoader();
        this.exportLeadsReportInExcelOfUser(data);
      },
      (error) => {
        this.auth.stopLoader();
      }
    );
  }

  private exportLeadsReportInExcelOfUser(leadsReport) {
    var exportList = [];
    var agentName: any;
    var jsonObj = {};
    for (let report of leadsReport) {
      jsonObj = {};
      jsonObj["Leads Id"] = report.leads.id;
      jsonObj["Client Name"] = report.leads.name;
      jsonObj["Client Email"] = report.leads.email;
      jsonObj["Status"] = report.status;
      jsonObj["Call Status"] = report.callStatus;
      jsonObj['Call Status Date'] = this.auth.toString(report.callStatusDate);
      jsonObj["Remark"] = report.message;
      jsonObj["Project"] = report.leads.projectName;
      jsonObj['Recieved Date'] = this.auth.toString(report.leads.assignedLeadsDate);
      jsonObj['Visit Status'] = report.visitStatus == true ? "Visit Done" : "Not Visited"
      jsonObj['Visit Done Date'] = this.auth.toString(report.visitDoneDate);
      jsonObj['Meeting Status'] = report.meetingStatus == true ? "Meeting Done" : "Meeting Not Done"
      jsonObj['Meeting Done Date'] = this.auth.toString(report.meetingDoneDate);
      if (report.user != null) {
        if (report.user.firstName != null) {
          agentName = report.user.firstName;
        }
        if (report.user.lastName != null) {
          agentName = agentName + " " + report.user.lastName;
        }
      } else {
        agentName = null;
      }
      jsonObj["Agent"] = agentName;
      // if (this.isValid) {
      //   jsonObj["Phone"] = report.leads.phone;
      // }
      exportList.push(jsonObj);
    }
    this.auth.exportAsExcelFile(exportList, "LeadsReportExcelSheet");
  }

  // Multiselect features

  //Poject

  onProjectSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedProjects.push(projObj);
  }

  onProjectDeSelect(item: any) {
    let index = this.selectedProjects.findIndex((x) => x["id"] === item.id);
    this.selectedProjects.splice(index, 1);
  }

  onSelectAllProject(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedProjects.push(projObj);
    }
  }

  onDeSelectAllProject(items: any) {
    this.selectedProjects = [];
  }

  //Source

  onSourceSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedSource.push(projObj);
  }

  onSourceDeSelect(item: any) {
    let index = this.selectedProjects.findIndex((x) => x["id"] === item.id);
    this.selectedSource.splice(index, 1);
  }

  onSelectAllSource(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedSource.push(projObj);
    }
  }

  onDeSelectAllSource(items: any) {
    this.selectedSource = [];
  }

  //Agents

  onEmpSelect(item: any) {
    var empObj = {};
    empObj["id"] = item.id;
    this.selectedEmp.push(empObj);
  }

  onEmpDeSelect(item: any) {
    let index = this.selectedEmp.findIndex((x) => x["id"] === item.id);
    this.selectedEmp.splice(index, 1);
  }

  onSelectAllEmp(items: any) {
    for (var item of items) {
      var empObj = {};
      empObj["id"] = item.id;
      this.selectedEmp.push(empObj);
    }
  }

  onDeSelectAllEmp(items: any) {
    this.selectedEmp = [];
  }

  // Status

  onLeadsStatusSelect(item: any) {
    var projObj = {};
    this.callStatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedLeadStatus.push(projObj);
    for (var status of this.selectedLeadStatus) {
      if (status.name == "Open") {
        this.openString = "open";
      } else if (status.name == "Decline") {
        this.declineString = "decline";
      } else if (status.name == "Close") {
        this.callStatus = [];
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    }
    console.log(this.selectedLeadStatus);
  }

  onLeadsStatusDeSelect(item: any) {
    this.callStatus = [];
    this.openString = null;
    this.declineString = null;
    this.closeString = null;
    let index = this.selectedLeadStatus.findIndex((x) => x["id"] === item.id);
    this.selectedLeadStatus.splice(index, 1);
    for (var status of this.selectedLeadStatus) {
      if (status.name == "Open") {
        this.openString = "open";
      } else if (status.name == "Decline") {
        this.declineString = "decline";
      } else if (status.name == "Close") {
        this.closeString = "close";
      }
    }
    if (this.openString != null && this.declineString == null) {
      this.callStatus = this.openCallStatus;
    } else if (this.openString == null && this.declineString != null) {
      this.callStatus = this.declineCallStatus;
    } else if (this.openString != null && this.declineString != null) {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    } else if (this.closeString != null) {
      this.callStatus = [];
    } else {
      this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    }
  }

  onLeadsStatusSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedLeadStatus.push(projObj);
    }
  }

  onLeadsStatusDeSelectAll(items: any) {
    this.callStatus = this.openCallStatus.concat(this.declineCallStatus);
    this.selectedLeadStatus = [];
  }

  // Call status

  onCallStatusSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedCallStatus.push(projObj);
  }

  onCallStatusDeSelect(item: any) {
    let index = this.selectedCallStatus.findIndex((x) => x["id"] === item.id);
    this.selectedCallStatus.splice(index, 1);
  }

  onCallStatusSelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedCallStatus.push(projObj);
    }
  }

  onCallStatusDeSelectAll(items: any) {
    this.selectedCallStatus = [];
  }

  selectedCreatedByList: Array<any> = [];

  leadsCreatedBySelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedCreatedByList.push(projObj);
    console.log(this.selectedCreatedByList);
  }

  leadsCreatedByDeSelect(item: any) {
    let index = this.selectedCreatedByList.findIndex(
      (x) => x["id"] === item.id
    );
    this.selectedCreatedByList.splice(index, 1);
  }

  leadsCreatedBySelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedCreatedByList.push(projObj);
    }
  }

  leadsCreatedByDeSelectAll(items: any) {
    this.selectedCreatedByList = [];
  }

  // Assigned to Dropdown

  selectedAssignedToList: Array<any> = [];

  leadsAssignedToSelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedAssignedToList.push(projObj);
    console.log(this.selectedAssignedToList);
  }

  leadsAssignedToDeSelect(item: any) {
    let index = this.selectedAssignedToList.findIndex(
      (x) => x["id"] === item.id
    );
    this.selectedAssignedToList.splice(index, 1);
  }

  leadsAssignedToSelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedAssignedToList.push(projObj);
    }
  }

  leadsAssignedToDeSelectAll(items: any) {
    this.selectedAssignedToList = [];
  }

  // Assigned By Dropdown

  selectedAssignedByList: Array<any> = [];

  leadsAssignedBySelect(item: any) {
    var projObj = {};
    projObj["id"] = item.id;
    projObj["name"] = item.name;
    this.selectedAssignedByList.push(projObj);
    console.log(this.selectedAssignedByList);
  }

  leadsAssignedByDeSelect(item: any) {
    let index = this.selectedAssignedByList.findIndex(
      (x) => x["id"] === item.id
    );
    this.selectedAssignedByList.splice(index, 1);
  }

  leadsAssignedBySelectAll(items: any) {
    for (var item of items) {
      var projObj = {};
      projObj["id"] = item.id;
      projObj["name"] = item.name;
      this.selectedAssignedByList.push(projObj);
    }
  }

  leadsAssignedByDeSelectAll(items: any) {
    this.selectedAssignedByList = [];
  }

  //Multiselect get data

  getProjects() {
    this.auth.getProjects().subscribe((data) => {
      var list: Array<any> = [];
      for (let project of data) {
        var obj: any = {};
        obj["id"] = project.id;
        obj["name"] = project.name;
        list.push(obj);
      }
      this.projectNameList = list;
    });
  }

  getSources() {
    this.auth.getSourceString().subscribe((data) => {
      var list: Array<any> = [];
      var id = 0;
      for (let source of data) {
        id = id + 1;
        var obj: any = {};
        obj["id"] = id;
        obj["name"] = source;
        list.push(obj);
      }
      this.sourceNameList = list;
    });
  }

  getAgents() {
    this.auth.getUsers().subscribe((data) => {
      var list: Array<any> = [];
      for (let user of data) {
        var obj: any = {};
        obj["id"] = user.id;
        obj["name"] = user.firstName + " " + user.lastName;
        list.push(obj);
      }
      this.userList = list;
    });
  }

  // Search leads by name, phone

  search(event) {
    this.auth.stopLoader();
    var text = event.target.querySelector("#search").value;
    if (text === "") {
      alert("Value is required.");
    } else {
      this.auth.searchClientNameOrPhone(text).subscribe(
        (data) => {
          this.leadsReport = data;
          this.config.totalItems = data.length;
          this.config.itemsPerPage = data.length;
          this.auth.stopLoader();
        },
        (error) => {
          console.log(error);
          this.auth.stopLoader();
        }
      );
    }
  }
}
