import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "src/app/services/auth.service";
@Component({
  selector: 'app-performance',
  templateUrl: './performance.component.html',
  styleUrls: ['./performance.component.css']
})
export class PerformanceComponent implements OnInit {
  userId: any;
  leadsReport: any;
  startDate: string;
  endDate: string;

  constructor(private route: ActivatedRoute, private router: Router, private auth: AuthService) { }
 
  ngOnInit() {
    this.userId = this.route.snapshot.queryParamMap.get('id');
    this.loadPerformanceData();
  }

  loadPerformanceData() {
    this.auth.performance(this.userId).subscribe((data) => {
      this.leadsReport = data;
    });
  }

  handleDeclineLeadsClick(status: string) {
    const queryParams = { id: this.userId };
  
    // Convert start and end dates to timestamps
    const startDateObj = this.startDate ? new Date(this.startDate).getTime() : null;
    const endDateObj = this.endDate ? new Date(this.endDate).getTime() : null;
  
    // Check if startDate and endDate are defined
    if (startDateObj && endDateObj) {
      // If startDate and endDate are defined, add them to queryParams as timestamps
      queryParams['status'] = status;
      queryParams['fromDate'] = startDateObj;
      queryParams['toDate'] = endDateObj;
    } else if (this.userId) {
      // If startDate and endDate are not defined, only add userId to queryParams
      queryParams['status'] = status;
    }
  
    // Navigate with the constructed queryParams
    this.router.navigate(['crm/show-all-leads-responce'], { queryParams: queryParams });
    alert("You clicked on Decline Leads card!");
  }
  applyfilter() {
    const startDateLong = new Date(this.startDate).getTime();
    const endDateLong = new Date(this.endDate).getTime();
    this.auth.opendeclinecountdata(this.userId, startDateLong, endDateLong).subscribe((data) => {
      this.leadsReport = data;
      console.log("fromdate"+ startDateLong);
      console.log("endDate"+endDateLong);

    });
  }
}
